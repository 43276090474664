import React from 'react'
import Layout from '../layout/defaultLayout'
import Container from '../components/layout/Container'

const NotFoundPage = () => (
  <Layout>
    <Container>
      <h1>Oeps...</h1>
      <p>Er ging iets mis!</p>
      {/* <img src="https://media.giphy.com/media/Y5hAot8zWKZjy/giphy.gif" alt=""/> */}
    </Container>
  </Layout>
)

export default NotFoundPage
